//获取字符长度
export const byteLength = function (str) {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
        let code = str.charCodeAt(i);
        if (code <= 0 && code <= 128) {
            len = len + 1;
        } else len = len + 2;
    }
    return len;
}

//验证是否为手机号
export const isMobile = function (mobile) {
    let pat = new RegExp(/^((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/);
    return pat.test(mobile)
}

//是否为邮箱
export const isEmail = function (email) {
    let pat = new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/);
    return pat.test(email)
}

//验证密码 
export const pwdver = function (pwd) {
    let regex = new RegExp('^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)[0-9A-Za-z_]{8,16}$');
    return regex.test(pwd)
}

//验证是否为金额
export const isMoney = function (str) {
    let regex = new RegExp('^(([0-9]|([1-9][0-9]{0,9}))((\.[0-9]{1,2})?))$');
    return regex.test(str)
}

//验证字符串是否为空
export const isNull = function (str) {
    if (str == "")
        return true;
    let regu = "^[ ]+$";
    let re = new RegExp(regu);
    return re.test(str);
}

//时间戳转Date对象
export function newTime(data) {
    let newTime;
    if (data) {
        let time = new Date(data);
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let date = time.getDate();
        newTime = year + '-' + addZero(month) + '-' + addZero(date);
    }
    return newTime;
}

//公用方法
function addZero(m) {
    return m < 10 ? '0' + m : m;
}

//建立一个可存取到该file的url
export function getObjectURL(file) {
    let url = null;
    if (window.createObjectURL != undefined) { // basic
        url = window.createObjectURL(file);
    } else if (window.URL != undefined) { // mozilla(firefox)
        url = window.URL.createObjectURL(file);
    } else if (window.webkitURL != undefined) { // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
    }
    return url;
}

//获取随机数
export function randomString(len) {
    len = len || 32;
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = chars.length;
    let pwd = '';
    for (var i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

//m,n为正整数的分子和分母 获取两个数字的比例 如800 1200 返回为[2,3]
export function reductionTo(m, n) {
    let arr = [];
    if (!isInteger(m) || !isInteger(n)) {
        console.log('m和n必须为整数');
        return;
    } else if (m <= 0 || n <= 0) {
        console.log('m和n必须大于0');
        return;
    }
    let a = m;
    let b = n;
    (a >= b) ? (a = m, b = n) : (a = n, b = m);
    if (m != 1 && n != 1) {
        for (let i = b; i >= 2; i--) {
            if (m % i == 0 && n % i == 0) {
                m = m / i;
                n = n / i;
            }
        }
    }
    arr[0] = m;
    arr[1] = n;
    return arr;
}

//判断一个数是否为整数
export function isInteger(obj) {
    return obj % 1 === 0
}

//判断一个数是否为正整数
export function isPositiveInteger(obj) {
    if (obj >= 0) {
        return obj % 1 === 0;
    } else {
        return '';
    }
}

export function number_format(number, decimals, dec_point, thousands_sep) {
    decimals = 2; //这里默认设置保留两位小数，也可以注释这句采用传入的参数
    /*
     * 参数说明：
     * number：要格式化的数字
     * decimals：保留几位小数
     * dec_point：小数点符号
     * thousands_sep：千分位符号
     * */
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number;
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    let sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep;
    let dec = (typeof dec_point === 'undefined') ? '.' : dec_point;
    let s = n.toString().split('.');
    let re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    } else {
        s[1] = s[1].substring(0, prec); //小数点位数超出长度时截取前面的位数
    }
    return s.join(dec);
}
/*金额格式化，三位数逗号分隔，小数点后保留两位*/
export function SumFormat(value){
    if(!value) return '0.00';
    let intPart =  Number(value)|0; //获取整数部分
    let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分每三位加一个逗号
    let floatPart = ".00"; //预定义小数部分
    if(typeof value !== 'string'){
        value = value.toString();
    }
    let value2Array = value.split(".");
    //=2表示数据有小数位
    if(value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if(floatPart.length > 2 ) {  //若小数位数在两位以上，则四舍五入保留两位小数
            return parseFloat(value).toFixed(2);
        } else if(floatPart.length == 1) {  //若小数位数只有一位，补0
            return intPartFormat + "." + floatPart + '0';
        } else {
            return intPartFormat + "." + floatPart;
        }
    } else {  //数据为整数
        return intPartFormat;
    }
}
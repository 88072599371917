<template>
  <div class="applyTrial">
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" title="提示" :visible.sync="show"
               width="500px">
      <div slot="title" class="title">{{ dialogTitle }}</div>
      <div style="padding: 0 30px;">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item prop="companyName">
            <el-input v-model="form.companyName" placeholder="公司名称" maxlength="34">
              <div slot="prefix" style="padding-left: 10px;">
                <img style="position: relative;top: 7px;"
                     :src="require('@/assets/img/home/form_1.png')"/>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="industryId">
            <el-select v-model="form.industryId" placeholder="所属行业" style="width: 100%;">
              <el-option v-for="item in options" :key="item.rid" :label="item.name" :value="item.rid"/>
              <div slot="prefix" style="padding-left: 10px;">
                <img style="position: relative;top: 7px;"
                     :src="require('@/assets/img/home/form_2.png')"/>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item prop="name">
            <el-input v-model="form.name" placeholder="你的姓名" maxlength="34">
              <div slot="prefix" style="padding-left: 10px;">
                <img style="position: relative;top: 8px;"
                     :src="require('@/assets/img/home/form_3.png')"/>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="position">
            <el-input v-model="form.position" placeholder="你的职位" maxlength="34">
              <div slot="prefix" style="padding-left: 10px;">
                <img style="position: relative;top: 7px;"
                     :src="require('@/assets/img/home/form_4.png')"/>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input v-model="form.phone" placeholder="你的手机号码" maxlength="11">
              <div slot="prefix" style="padding-left: 10px;">
                <img style="position: relative;top: 7px;"
                     :src="require('@/assets/img/home/form_5.png')"/>
              </div>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="submit" @click="sub">{{ dialogTitle=='申请试用' ? '立即申请' : '立即质询' }}</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {serviceApplyTrial, industry, addServicePlatformSolution} from '@/api/defaultApi'
import {isMobile} from '@/common/tools.js'

export default {
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号'));
      } else {
        if (isMobile(value)) {
          return callback()
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    }
    return {
      show: false,
      options: [],
      form: {
        companyName: "",
        industryId: null,
        industryName: "",
        name: "",
        phone: "",
        position: "",
        servicePlatform: ""
      },
      rules: {
        companyName: [
          {required: true, message: '请输入企业名称', trigger: 'blur'},
        ],
        industryId: [
          {required: true, message: '请输选择行业', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        position: [
          {required: true, message: '请输入你的职位', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入你手机号', trigger: 'blur'},
          {validator: checkPhone, trigger: 'blur'}
        ]
      }
    }
  },
  watch:{
    servicePlatform(newVal, oldVal) {
      if (newVal != oldVal) {
        this.form.servicePlatform=newVal;
      }
    }
  },
  props: {
    servicePlatform:{
      type: String,
      default: ''
    },
    dialogTitle: {
      type: String,
      default: '申请试用'
    }
  },
  created() {
    this.getIndustry()
  },
  methods: {
    //获取行业类别
    getIndustry() {
      industry().then(res => {
        if (res.code == 200) {
          this.options = res.content
        }
      })
    },
    //提交
    sub() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const data = this.options.find(row => this.form.industryId == row.rid);
          this.form.industryName = data.name;
          if (this.dialogTitle=='申请试用') {
            serviceApplyTrial(this.form).then(res => {
              if (res.code == 200) {
                this.$refs['ruleForm'].resetFields();
                this.show = false;
                this.$message.success('您的信息已提交成功')
              } else {
                this.$message.error(res.msg)
              }
            })
          }else{
            let form={
              companyName: this.form.companyName,
              industryId: this.form.industryId,
              industryName: this.form.industryName,
              name: this.form.name,
              phone: this.form.phone,
              position: this.form.position,
              solution: this.form.servicePlatform
            }
            addServicePlatformSolution(form).then(res => {
              if (res.code == 200) {
                this.$refs['ruleForm'].resetFields();
                this.show = false;
                this.$message.success('您的信息已提交成功')
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    // 弹出弹窗
    showApply(num) {
      this.show = true
      this.form.servicePlatform = num
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.submit {
  width: 100%;
  text-align: center;
  height: 50px;
  background-color: #1935DE;
  color: #fff;
  font-size: 16px;
  line-height: 50px;
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;
}
</style>
<style scoped>
.applyTrial >>> .el-input--prefix .el-input__inner {
  text-indent: 20px;
  background-color: #FCFCFF;
  border-color: #DBE0FF;
  height: 50px;
}
</style>
